import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import GatsbyImage from 'gatsby-image';

const StyledLogo = styled(GatsbyImage)`
  ${tw`w-48 mt-5 max-w-150`}
`;

const Logo = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <StyledLogo
      alt={'Katherine Méndez'}
      fluid={image.sharp.fluid}
      fadeIn={'soft'}
    />
  );
};

export default Logo;
