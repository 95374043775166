import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faInstagramSquare,
  faLinkedin,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';

export const addIcons = () => {
  library.add(faFacebookSquare, faLinkedin, faInstagramSquare);
};
