import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledSpan = styled.span`
  ${tw`flex justify-center`}
`;

const socialMediaLinks = {
  'instagram-square': 'https://instagram.com/katherinecmb?igshid=w76lnfndf3rm',
  linkedin: 'https://www.linkedin.com/in/katherine-mendez-bilotta-b69185116',
  'facebook-square': 'https://www.facebook.com/Katherinemendezb',
};

const SocialMedia = () => {
  return (
    <StyledSpan>
      {Object.entries(socialMediaLinks).map(([k, v], i) => (
        <a
          href={v}
          key={i}
          target={'_blank'}
          rel={'noreferrer'}
          css={css`
            :not(:last-child) {
              ${tw`pr-4`}
            }
          `}
        >
          <FontAwesomeIcon icon={['fab', k]} size={'3x'} />
        </a>
      ))}
    </StyledSpan>
  );
};

export default SocialMedia;
