import React from 'react';
import tw, { css } from 'twin.macro';

import { addIcons } from '../utils';

import Layout from '../components/layout';
import Avatar from '../components/Avatar';
import Logo from '../components/Logo';
import SEO from '../components/seo';
import SocialMedia from '../components/SocialMedia';

addIcons();

const IndexPage = () => (
  <Layout>
    <SEO lang={'es'} title={'Coming soon'} />
    <Avatar />
    <span
      css={css`
        ${tw`text-center text-yellow font-display italic font-light text-shadow`}
      `}
    >
      <h1
        css={css`
          ${tw`md:text-5xl text-2xl`}
        `}
      >
        Digital Marketing Strategist
      </h1>
      <h4
        css={css`
          ${tw`md:text-3xl text-lg`}
        `}
      >
        Nos vemos pronto...
      </h4>
      <SocialMedia />
      <h4>@katherinecmb</h4>
    </span>
    <Logo />
  </Layout>
);

export default IndexPage;
