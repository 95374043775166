import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import GatsbyImage from 'gatsby-image';

const StyledAvatar = styled(GatsbyImage)`
  ${tw`w-3/12 min-w-350`}
`;

const Avatar = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "avatar.png" }) {
        sharp: childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <StyledAvatar
      alt={'Katherine Méndez'}
      fluid={image.sharp.fluid}
      fadeIn={'soft'}
    />
  );
};

export default Avatar;
